<template>

</template>
<script>
    import {request} from "../app/api/Client";

    export default {
        methods : {
            async saveCalenderToken(client) {
                let code = this.$route.query.code || null;
                let scope = this.$route.query.scope || null;
                let state = this.$route.query.state || null;
                if(_.isNull(code)) {
                     await this.$router.push({ name : 'dashboard' });
                     this.$toast.error('Invalid signature');
                    return 0;
                }
                try {
                    let {data: {data, message}} = await request.post('/company/remote-interview/client-token/save', {
                        code,
                        client : client,
                    });
                    this.$toast.success(message);
                } catch (err) {
                    this.$toast.error('Calender login failed. Please retry.');
                }
                this.closeWindow();
            },
            closeWindow() {
                setTimeout(function () {
                    window.top.close()
                }, 2000);
            }
        },
        mounted() {
               this.saveCalenderToken(this.$route.params.client);
        }
    }
</script>
